<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="12"
        xm="12"
      >
        <v-card>
          <v-toolbar
            height="40"
            dense
            flat
            color="red"
            dark
          >
            <h3>Estadisticas de venta</h3>
          </v-toolbar>
          <v-data-table
            dense
            :headers="headers"
            :items="proyectos"
          >
            <template v-slot:item="{ item }">
              <tr @dblclick="editar(item)">
                <td>{{ item.mes }}</td>
                <td>{{ item.ventasaño1 }}</td>
                <td>{{ item.ventasaño2 }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      proyectos: [],
      headers: [

        { text: 'Mes', value: 'mes'},
        { text: 'Ventas año: 2019', value: 'ventasaño1' },
        { text: 'Ventas año: 2020', value: 'ventasaño2' },
  
      ],
      
      // json_fields: {
      //   Estatus: "completo",
      //   Id: "idproyecto",
      //   FechaInicio: "inicio",
      //   FechaFin: "meta",

      //   NombreProyecto: "nomproy",
      //   NumCliente: "numcli",
      //   NumProyecto: "numproy",
      //   Observaciones: "obs",
      //   Precio: "precio",
      // },
      // json_meta: [
      //   [
      //     {
      //       key: "charset",
      //       value: "utf-8",
      //     },
      //   ],
      // ],
      // json_data: [],
    }
  },
}
</script>

<style>

</style>